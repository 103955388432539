import React from 'react';
import { Footer } from 'shared/ui/footer/footer';
import { Header } from 'shared/ui/header/header';
import { ReactComponent as BigPicture } from 'shared/assets/images/inkscape/concepts/big-picture.svg';
import { ReactComponent as DefinitionsTables } from 'shared/assets/images/inkscape/concepts/definitions-tables.svg';
import { ReactComponent as DefinitionsRelationships } from 'shared/assets/images/inkscape/concepts/definitions-relationships.svg';
import { ReactComponent as MetalForms } from 'shared/assets/images/inkscape/concepts/forms-metal.svg';
import { ReactComponent as CompanyForms } from 'shared/assets/images/inkscape/concepts/company-forms.svg';
import { ReactComponent as MechanismGeneral } from 'shared/assets/images/inkscape/concepts/mechanism-general.svg';
import { ReactComponent as MechanismBrowser } from 'shared/assets/images/inkscape/concepts/mechanism-browser.svg';
import { Path } from 'shared/path';
import { Break, ConceptLink, ConceptStyles, ConceptsCallToAction } from './concepts_base';


export const ConceptsOverviewComponent = () => {
  return (
    <div className={ConceptStyles.Page}>
      <Header/>
      <div className={ConceptStyles.Content}>
        <h1 className={ConceptStyles.PageTitle}>
          Concepts
        </h1>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              MECHAFORM
            </h3>
            <h4 className={ConceptStyles.Main}>
              The view from<Break/>
              10,000 feet
            </h4>
            <p className={ConceptStyles.Blurb}>
              Mechaform has three concepts that will let you build anything you want:
              Definitions, Forms, and Mechanisms
            </p>
          </div>
          <div className='flex-1 p-12'>
            <BigPicture className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              DEFINITIONS
            </h3>
            <h4 className={ConceptStyles.Main}>
              A seamless and flexible database
            </h4>
            <p className={ConceptStyles.Blurb}>
              Definitions tell us what to store. They are kind of like tables or spreadsheets.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <DefinitionsTables className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.TitleHidden}>
              DEFINITIONS
            </h3>
            <h4 className={ConceptStyles.Main}>
              Interlinked objects,<Break/>
              complex connections
            </h4>
            <p className={ConceptStyles.Blurb}>
              Unlike spreadsheets, Definitions support relationships, enabling richer formulas and automation.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <DefinitionsRelationships className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              FORMS
            </h3>
            <h4 className={ConceptStyles.Main}>
              Drag-and-drop<Break/>
              custom templates
            </h4>
            <p className={ConceptStyles.Blurb}>
              Forms give visual shape to your definitions. Think about how blacksmiths <em>form</em> a bar of metal into an anvil, a sword, or a cast-iron pan.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <MetalForms className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.TitleHidden}>
              FORMS
            </h3>
            <h4 className={ConceptStyles.Main}>
              Same object,<Break/>
              different forms
            </h4>
            <p className={ConceptStyles.Blurb}>
              Forms let you customize for different tasks while working on the same underlying data.
            </p>
          </div>
          <div className='flex-1 pr-10'>
            <CompanyForms className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              MECHANISMS
            </h3>
            <h4 className={ConceptStyles.Main}>
              When this happens,<Break/>
              do that
            </h4>
            <p className={ConceptStyles.Blurb}>
              Mechanisms link everything together &mdash; no code required.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <MechanismGeneral className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              MECHANISMS
            </h3>
            <h4 className={ConceptStyles.Main}>
              Do that...<Break/>
              but on the browser
            </h4>
            <p className={ConceptStyles.Blurb}>
              Send users to a confirmation form, or a payment page, or even show a popup on their screen!
            </p>
          </div>
          <div className='flex-1 p-12'>
            <MechanismBrowser className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className='flex justify-end'>
          <ConceptLink kind='next' path={Path.ConceptsDefinitions}>
            Definitions
          </ConceptLink>
        </div>
        <ConceptsCallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
