import React from 'react';
import Styles from './footer.module.css';

export function Footer() {
  return (
    <div className='py-3 bg-gray-800 text-white'>
      <div className='max-w-screen-xl mx-auto px-2 flex flex-wrap items-center'>
        <img className={Styles.logo}/>
        <div className='flex-none ml-auto'>
          © Mechaform 2024
        </div>
      </div>
    </div>
  );
}
