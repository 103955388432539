import React from 'react';

export function useOnce(fn: React.EffectCallback, deps?: React.DependencyList) {
  const once = React.useRef(true);
  React.useEffect(() => {
    if (once.current) {
      once.current = false;
      fn();
    }
  }, deps);
}

type CleanupFunction = () => void;

export function onEffectResizeObserver(element: Element, callback: ResizeObserverCallback): CleanupFunction {
  const actualResizeObserverCallback = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (!Array.isArray(entries) || !entries.length) {
      return;
    }
    callback(entries, observer);
  };

  // Creating an instance of ResizeObserver
  const observer = new ResizeObserver(actualResizeObserverCallback);
  observer.observe(element);

  return () => {
    observer.disconnect();
  };
}

export function useElementOnScreen<T extends Element>(options?: IntersectionObserverInit) {
  const ref = React.useRef<T>(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (!isVisible && entry.isIntersecting) {
      setIsVisible(entry.isIntersecting);
    }
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return [ref, isVisible];
  // pass
}