import anime from 'animejs';

export const ANIMATION_TIME_SCALE = 1;

export type AnimationScene = {
  explainer: HTMLDivElement;
  stageWYSIWYG: SVGSVGElement;
  stageFormSubmit: SVGSVGElement;
  stageMultiform: SVGSVGElement;
  stageMechanism: SVGSVGElement;
  pointer: SVGSVGElement;
  palette: SVGSVGElement;
  createFormBox: HTMLDivElement;
  createForm: SVGSVGElement;
  reviewForm: SVGSVGElement;
  table: SVGSVGElement;
};


enum AnimatorState {
  Setup = 'Setup',
  Main = 'Main',
  Cleanup = 'Cleanup',
}

export abstract class ExplainerBase {
  state: AnimatorState;
  cancelled: boolean;
  animation: anime.AnimeTimelineInstance | null;
  private readonly secretOnAnimationComplete: () => void;

  constructor(onAnimationComplete: () => void) {
    this.state = AnimatorState.Setup;
    this.cancelled = false;
    this.animation = null;
    this.secretOnAnimationComplete = onAnimationComplete;
  }

  onAnimationComplete = () => {
    // This junk exists just to stop animations from calling the
    // onComplete callback AFTER they have been paused and skipped
    if (!this.cancelled) {
      this.secretOnAnimationComplete();
    }
  };

  async startForward(scene: AnimationScene) {
    this.animation = await this._setupForward(scene);
    if (this.animation == null) {
      return;
    }
    this.state = AnimatorState.Setup;
    await runAnimation(this.animation);

    if (this.cancelled) {
      return;
    }

    const animation = await this._init(scene);
    if (this.cancelled) {
      return;
    }

    this.state = AnimatorState.Main;
    this.animation = animation;
    this.animation?.play();
  }

  async startReverse(scene: AnimationScene) {
    this.animation = await this._setupReverse(scene);
    if (this.animation == null) {
      return;
    }
    this.state = AnimatorState.Setup;
    await runAnimation(this.animation);

    if (this.cancelled) {
      return;
    }

    const animation = await this._init(scene);
    if (this.cancelled) {
      return;
    }

    this.state = AnimatorState.Main;
    this.animation = animation;
    this.animation?.play();
  }

  replay() {
    this.animation?.restart();
    return this.animation?.finished;
  }

  skip(scene: AnimationScene) {
    this.cancelled = true;
    this.animation?.seek(this.animation.duration);
    this.animation?.pause();
    this._cleanup(scene);
  }

  // The reason we go through a lot of shit to force everything into one TimelineInstance
  // is so that we can pause and fast forward the animation any time we want.
  // That's really hard to do with animation callbacks calling other animations everywhere
  abstract _setupForward(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null>;
  abstract _setupReverse(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null>;
  abstract _init(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null>;
  abstract _cleanup(scene: AnimationScene): Promise<void>;
}

export type LocationXY = {
  x: string;
  y: string;
  el: Element;
};

export function dxyToTransformPercentage(el: Element, dx: number, dy: number): [string, string] {
  // Let's say we want to translate an element 10px to the right and it has width 25px
  // The 25px shrinks based on screen size because it's responsive, so the 10px must shrink as well
  // This function turns that 10px translation into 40% so that it respects the object shrinking

  return [
    `${(dx / el.scrollWidth) * 100}%`,
    `${(dy / el.scrollHeight) * 100}%`,
  ];
}

export enum FancyTransitionKind {
  FadeIn,
  FadeOutIn,
  Translate,
}

type FancyTransitionFadeIn = {
  kind: FancyTransitionKind.FadeIn;
  node: Element;
};

type FancyTransitionTranslate = {
  kind: FancyTransitionKind.Translate;
  node: Element;
};

export type FancyTransition =
  | FancyTransitionFadeIn
  | FancyTransitionTranslate;

export async function overlayObject(
  stage: SVGSVGElement,
  selector: string,
  object: HTMLElement | SVGSVGElement,
) {
  const target = stage.querySelector(selector);
  if (target == null) {
    console.warn(`Could not find '${selector}' in`, stage);
    return;
  }

  const rectStage = stage.getBoundingClientRect();
  const rectTarget = target.getBoundingClientRect();

  const height = rectTarget.height / rectStage.height * 100;
  const width = rectTarget.width / rectStage.width * 100;
  const top = (rectTarget.y - rectStage.y) / rectStage.height * 100;
  const left = (rectTarget.x - rectStage.x) / rectStage.width * 100;

  object.style.height = `${height}%`;
  object.style.width = `${width}%`;
  object.style.top = `${top}%`;
  object.style.left = `${left}%`;
}

export async function recenterObject(
  stage: SVGSVGElement,
  selector: string,
  object: HTMLElement | SVGSVGElement,
) {
  const target = stage.querySelector(selector);
  if (target == null) {
    console.warn(`Could not find '${selector}' in`, stage);
    return;
  }

  const rectStage = stage.getBoundingClientRect();
  const rectTarget = target.getBoundingClientRect();
  const rectObject = object.getBoundingClientRect();

  const targetCenterY = rectTarget.y + rectTarget.height / 2;
  const targetCenterX = rectTarget.x + rectTarget.width / 2;
  const top = (targetCenterY - rectStage.y - rectObject.height / 2) / rectStage.height * 100;
  const left = (targetCenterX - rectStage.x - rectObject.width / 2) / rectStage.width * 100;

  object.style.top = `${top}%`;
  object.style.left = `${left}%`;
}

export async function makeFancyTransition(transitions: FancyTransition[]): Promise<anime.AnimeTimelineInstance> {
  // Compute the bounding boxes of all nodes, then wait for the next animation frame
  // On the next animation frame (which is after a browser reflow),
  // translate all the nodes to their original locations then apply a linear translation transition
  // to translate all of them to wherever they've gotten reflowed to
  // Lastly, once all those animations are done, resolve the promise that this returns
  // Use it like this:
  // fancyTranslateTransition([scene.object, scene.object2, scene.object3]);
  // This will

  const before = transitions.map(t => t.node.getBoundingClientRect());
  const timeline = anime.timeline();

  return new Promise(resolve => {
    requestAnimationFrame(() => {
      for (let i = 0; i < transitions.length; i++) {
        const trans = transitions[i];
        const bef = before[i];
        const aft = trans.node.getBoundingClientRect();
        if (trans.kind === FancyTransitionKind.FadeIn) {
          timeline.add({
            targets: trans.node,
            opacity: [0, 1],
            duration: 400,
            easing: 'linear',
          }, 0);
        } else if (trans.kind === FancyTransitionKind.Translate) {
          const [ctx, cty] = currentTransformTranslate(trans.node);

          timeline.add({
            targets: trans.node,
            translateX: [bef.x + ctx - aft.x, 0],
            translateY: [bef.y + cty - aft.y, 0],
            scaleX: [bef.width / aft.width, 1],
            scaleY: [bef.height / aft.height, 1],
            duration: 400,
            easing: 'linear',
          }, 0);
        }
      }

      resolve(timeline);
    });
  });
}

export function runAnimation(animation: anime.AnimeTimelineInstance): Promise<void> {
  return new Promise(resolve => {
    animation.complete = () => {
      resolve();
    };
    animation.play();
  });
}

export function getAnimationProgress(anim: anime.AnimeInstance) {
  // Computes the animation progress as a number from 0 to 1,
  // correctly omitting the delay and endDelay
  const delay = anim.delay ?? 0;
  const endDelay = (anim as unknown as { endDelay: number }).endDelay as number ?? 0;
  const duration = anim.duration - delay - endDelay;
  const total = anim.duration;
  return Math.min((anim.progress / 100 - delay / total) * total / duration, 1);
}

export function getElementCenter(el: Element): [number, number] {
  const rect = el.getBoundingClientRect();
  const xmid = (rect.left + rect.right) / 2;
  const ymid = (rect.top + rect.bottom) / 2;
  return [xmid, ymid];
}

export function getElementTopLeft(el: Element): [number, number] {
  const rect = el.getBoundingClientRect();
  return [rect.left, rect.top];
}

export function getElementBottomRight(el: Element): [number, number] {
  const rect = el.getBoundingClientRect();
  return [rect.right, rect.bottom];
}

export function getElementMiddleLeft(el: Element): [number, number] {
  const rect = el.getBoundingClientRect();
  return [rect.left, (rect.top + rect.bottom) / 2];
}

export function getElementMiddleRight(el: Element): [number, number] {
  const rect = el.getBoundingClientRect();
  return [rect.right, (rect.top + rect.bottom) / 2];
}

export function makeHidden(root: SVGElement, selector: string) {
  // To be honest, I don't know when animejs prefers to use style and when it prefers to set the opacity attribute.
  // Having either one set to zero while the other one is set to 1 will make something invisible can be not-good
  // But this implementation seems to work consistently.
  const el: SVGElement | null = root.querySelector(selector);
  if (el == null) {
    return;
  } else if (el.getAttribute('opacity') != null) {
    el.setAttribute('opacity', '0');
  } else if (el.style.opacity != null) {
    el.style.opacity = '0';
  } else {
    el.style.opacity = '0';
  }
}

export function makeVisible(root: SVGElement, selector: string) {
  const el: SVGElement | null = root.querySelector(selector);
  if (el == null) {
    return;
  } else if (el.getAttribute('opacity') != null) {
    el.setAttribute('opacity', '1');
  } else if (el.style.opacity != null) {
    el.style.opacity = '1';
  }
}

type TranslateOptions = {
  offsetX?: number;
  offsetY?: number;
};

export async function translateOnto(node: SVGSVGElement, target: Element, opts?: TranslateOptions) {
  const nodeRect = node.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();
  const [ctx, cty] = currentTransformTranslate(node);

  const tx = targetRect.x + ctx - nodeRect.x + (opts?.offsetX ?? 0);
  const ty = targetRect.y + cty - nodeRect.y + (opts?.offsetY ?? 0);
  const sx = targetRect.width / node.width.baseVal.value;
  const sy = targetRect.height / node.height.baseVal.value;

  node.style.transform = `translateX(${tx}px) translateY(${ty}px) scaleX(${sx}) scaleY(${sy})`;
}

export async function nextAnimationFrame(): Promise<void> {
  return new Promise<void>((resolve) => {
    requestAnimationFrame(() => resolve());
  });
}

export function currentTransformTranslate(el: Element) {
  const style = window.getComputedStyle(el);
  const matrix = new WebKitCSSMatrix(style.transform);
  return [matrix.m41, matrix.m42, matrix.m11, matrix.m22];
}