import React from 'react';
import { ReactComponent as MechanismAbstract } from 'shared/assets/images/inkscape/concepts/mechanism-abstract.svg';
import { ReactComponent as MechanismTrigger } from 'shared/assets/images/inkscape/concepts/mechanism-trigger.svg';
import { ReactComponent as MechanismAction } from 'shared/assets/images/inkscape/concepts/mechanism-action.svg';
import { ReactComponent as MechanismExternal } from 'shared/assets/images/inkscape/concepts/mechanism-external.svg';
import { ReactComponent as MechanismFormLinked } from 'shared/assets/images/inkscape/concepts/mechanism-form-linked.svg';
import { Header } from 'shared/ui/header/header';
import { Footer } from 'shared/ui/footer/footer';
import { Path } from 'shared/path';
import { Break, ConceptLink, ConceptStyles, ConceptsCallToAction } from './concepts_base';
import { FaqAnswer, FaqItem, FaqQuestion, FaqSection } from './faq';

export const ConceptsMechanismsComponent = () => {
  return (
    <div className={ConceptStyles.Page}>
      <Header/>
      <div className={ConceptStyles.Content}>
        <h1 className={ConceptStyles.PageTitle}>
          Mechanisms
        </h1>
        <p className={ConceptStyles.PageBlurb}>
          Concepts and Terminology
        </p>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              MECHANISM
            </h3>
            <h4 className={ConceptStyles.Main}>
              If this happens,<Break/>then do that
            </h4>
            <p className={ConceptStyles.Blurb}>
              Mechanisms do whatever you want. No coding. Just point and click.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <MechanismAbstract className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              TRIGGER
            </h3>
            <h4 className={ConceptStyles.Main}>
              Start with a Trigger
            </h4>
            <p className={ConceptStyles.Blurb}>
              Triggers run Mechanisms. Triggers could be anything from buttons clicked to emails received.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <MechanismTrigger className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              ACTION
            </h3>
            <h4 className={ConceptStyles.Main}>
              Automate things<Break/>with Actions
            </h4>
            <p className={ConceptStyles.Blurb}>
              Actions do things like send emails, look up data, conditional branches, loop through objects, and more!
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <MechanismAction className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              INDEPENDENT MECHANISM
            </h3>
            <h4 className={ConceptStyles.Main}>
              Started by<Break/>things happening
            </h4>
            <p className={ConceptStyles.Blurb}>
              Watch the world for events like received text messages, emails, or the start of a new day. If you&apos;ve seen other workflow engines, this is similar to that.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <MechanismExternal className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              FORM-BASED MECHANISM
            </h3>
            <h4 className={ConceptStyles.Main}>
              Started by widgets
            </h4>
            <p className={ConceptStyles.Blurb}>
              Show the user a popup or send him to a payment page when a certain button is clicked.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <MechanismFormLinked className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        {/* <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              EXTENSION
            </h3>
            <h4 className={ConceptStyles.Main}>
              Enhance your setup by installing extensions
            </h4>
            <p className={ConceptStyles.Blurb}>
              Add more triggers or actions to increase the capabilities of your mechanisms without getting overwhelmed.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <Extension className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div> */}
        <FaqSection>
          <FaqItem>
            <FaqQuestion>
              What can form-based mechanisms do that independent mechanisms can&apos;t?
            </FaqQuestion>
            <FaqAnswer>
              Form-based mechanisms can do things like redirect the user&apos;s browser or show him a popup. You can use this for things like collecting payments, letting customers download a file, or anything else you need control of their browsers to do.
            </FaqAnswer>
          </FaqItem>
          <FaqItem>
            <FaqQuestion>
              What if you don&apos;t have an integration that I want?
            </FaqQuestion>
            <FaqAnswer>
              Just let us know and we&apos;ll see what we can do. One day, we hope to have thousands of integrations maintained by us, a whole ecosystem of extensions made by third parties, and the tools to let you patch in anything else if you have your own custom proprietary internal software.
            </FaqAnswer>
          </FaqItem>
        </FaqSection>
        <div className='flex justify-start'>
          <ConceptLink kind='previous' path={Path.ConceptsForms}>
            Forms
          </ConceptLink>
        </div>
        <ConceptsCallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
