import React from 'react';
import classNames from 'classnames';
import { ReactComponent as Chevron } from 'shared/assets/icons/chevron.svg';
import { ConceptStyles } from './concepts_base';
import Styles from './concepts.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FaqOpen = React.createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>]>(null as any);

type FaqQuestionProps = {
  children: React.ReactNode;
};

export function FaqQuestion({ children }: FaqQuestionProps) {
  const [open, setOpen] = React.useContext(FaqOpen);

  const toggleOpen = React.useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <h4 className='inline-flex items-center text-purple-400 hover:text-purple-300 cursor-pointer select-none' onClick={toggleOpen}>
      <Chevron className={classNames('mr-2', Styles.chevron, open && Styles.expanded)} height='12px' width='12px'/>
      <div className='text-xl font-bold '>
        {children}
      </div>
    </h4>
  );
}

type FaqAnswerProps = {
  children: React.ReactNode;
};

export function FaqAnswer({ children }: FaqAnswerProps) {
  const [open] = React.useContext(FaqOpen);
  const [height, setHeight] = React.useState(0);
  const [opacity, setOpacity] = React.useState(0);
  const answer = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const updateState = () => {
      if (answer.current == null) {
        return;
      }

      setHeight(open ? answer.current.scrollHeight : 0);
      setOpacity(open ? 1 : 0);
    };

    updateState();
    window.addEventListener('resize', updateState);

    return () => {
      window.removeEventListener('resize', updateState);
    };
  }, [open]);

  return (
    <h4
      className='transition-all duration-200 overflow-hidden'
      style={{ height, opacity }}
      ref={answer}
    >
      <div className='text-md text-purple-500 box-border mx-4 mt-3'>
        {children}
      </div>
    </h4>
  );
}

type FaqItemProps = {
  children: React.ReactNode;
};

export function FaqItem({ children }: FaqItemProps) {
  const openState = React.useState(false);

  return (
    <div className='border-b py-3'>
      <FaqOpen.Provider value={openState}>
        {children}
      </FaqOpen.Provider>
    </div>
  );
}

type FaqSectionProps = {
  children: React.ReactNode;
};

export function FaqSection({ children }: FaqSectionProps) {
  return (
    <div>
      <h1 className={ConceptStyles.PageBlurb}>
        Frequently Asked Questions
      </h1>
      { children }
    </div>
  );
}