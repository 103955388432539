import anime from 'animejs';
import {
  ANIMATION_TIME_SCALE,
  AnimationScene,
  ExplainerBase,
  FancyTransitionKind,
  LocationXY,
  dxyToTransformPercentage,
  getElementBottomRight,
  getElementMiddleLeft,
  makeFancyTransition,
  makeHidden,
  makeVisible,
  nextAnimationFrame,
  overlayObject,
} from './explainer_utils';
import { DrawAnimation, UndrawElement, WaitAnimation } from './animations';
/* eslint-disable @typescript-eslint/no-explicit-any */

export class ExplainerFormSubmit extends ExplainerBase {
  async _init(scene: AnimationScene) {
    const [formx, formy] = getElementBottomRight(scene.createForm);

    const findFormTranslation = (root: Element, selector: string): LocationXY | null => {
      const child = root.querySelector(selector);
      if (child == null) {
        return null;
      }

      const [dx, dy] = getElementMiddleLeft(child);

      const [x, y] = dxyToTransformPercentage(scene.createForm, dx - formx, dy - formy);

      return {
        x,
        y,
        el: child,
      };
    };

    const fName: SVGGeometryElement = scene.createForm.querySelector('.name-t') as SVGGeometryElement;
    const fBday: SVGGeometryElement = scene.createForm.querySelector('.bday-t') as SVGGeometryElement;
    const fCheck: SVGSVGElement = scene.createForm.querySelector('.check-x') as SVGSVGElement;
    const fBdayPlaceholder: SVGSVGElement = scene.createForm.querySelector('.bday-p') as SVGSVGElement;
    const fUploadIcon: SVGSVGElement = scene.createForm.querySelector('.upload-i') as SVGSVGElement;
    const fUploadText: SVGSVGElement = scene.createForm.querySelector('.upload-t') as SVGSVGElement;
    const fUploadBar: SVGSVGElement = scene.createForm.querySelector('.upload-b') as SVGSVGElement;
    const fUploadProgress: NodeListOf<SVGSVGElement> = scene.createForm.querySelectorAll('defs .upload-b-grad .upload-b-fill');
    const tRow4 = findFormTranslation(scene.table, '.row4');
    const tRow5 = findFormTranslation(scene.table, '.row5');
    const tRow6 = findFormTranslation(scene.table, '.row6');

    if (
      fName == null ||
      fBday == null ||
      fCheck == null ||
      fUploadText == null ||
      fUploadBar == null ||
      fUploadIcon == null ||
      fUploadProgress.length == 0 ||
      tRow4 == null ||
      tRow5 == null ||
      tRow6 == null
    ) {
      return null;
    }

    scene.createForm.style.transformOrigin = '100% 100%';
    scene.createForm.style.transform = 'perspective(100px)';

    function BirthdayPlaceholderHide(): anime.AnimeParams {
      return {
        targets: fBdayPlaceholder,
        opacity: 0,
        duration: 1,
      };
    }

    function SetCheckmarkAnimation(): anime.AnimeParams {
      return {
        targets: fCheck,
        duration: 1,
        endDelay: 250,
        opacity: 1,
      };
    }

    function FormRevealAnimation(opts?: { endDelay?: number }): anime.AnimeParams {
      return {
        targets: scene.createFormBox,
        opacity: 1,
        duration: 250,
        endDelay: opts?.endDelay ?? 250,
      };
    }

    function addFileUploadAnimation(opts: {
      timeline: anime.AnimeTimelineInstance;
    }) {
      const timeline = opts.timeline;

      const base = timeline.duration;

      // Hide the icon
      timeline.add({
        targets: fUploadIcon,
        opacity: 0,
        duration: 1,
      });

      timeline.add({
        targets: fUploadProgress,
        offset: [0, 1],
        duration: 300,
        easing: 'easeInOutQuad',
      });

      // Reveal filename
      timeline.add({
        targets: fUploadText,
        opacity: 1,
        duration: 1,
      }, base);

      // Reveal the bar
      timeline.add({
        targets: fUploadBar,
        opacity: 1,
        duration: 1,
        endDelay: 400,
      }, base);
    }

    function addFormResetAnimation(opts: {
      timeline: anime.AnimeTimelineInstance;
    }) {
      const timeline = opts.timeline;
      const base = timeline.duration;

      timeline.add({
        targets: [fCheck, fUploadText, fUploadBar],
        duration: 1,
        opacity: 0,
      });

      timeline.add({
        targets: [fBdayPlaceholder, fUploadIcon],
        opacity: 1,
        duration: 1,
      }, base);

      timeline.add({
        targets: [fName, fBday],
        duration: 1,
        begin: () => {
          UndrawElement(fName);
          UndrawElement(fBday);
        },
      }, base);
    }

    function addTableResetAnimation(opts: {
      timeline: anime.AnimeTimelineInstance;
      rows: Element[];
    }) {
      const timeline = opts.timeline;
      const base = timeline.duration;
      // Hide the rows
      timeline.add({
        targets: opts.rows,
        opacity: 0,
        duration: 400,
      });

      // Reveal the form
      timeline.add({
        targets: scene.createFormBox,
        opacity: 1,
        duration: 400,
      }, base);
    }

    function addSkidooAnimation(opts: {
      timeline: anime.AnimeTimelineInstance,
      row: LocationXY,
    }) {
      const timeline = opts.timeline;
      const base = opts.timeline.duration;
      const SKIDOO_DURATION = 500;
      const TABLE_REVEAL_DELAY = 700; // This should be a bit over the skidoo duration
      const TABLE_REVEAL_DURATION = 400;

      // Table row reveal
      timeline.add({
        targets: opts.row.el,
        opacity: 1,
        delay: TABLE_REVEAL_DELAY * ANIMATION_TIME_SCALE,
        duration: TABLE_REVEAL_DURATION * ANIMATION_TIME_SCALE,
      });

      // Form skidoo
      timeline.add({
        targets: scene.createForm,
        translateZ: -1000,
        rotateY: 45,
        skew: 90,
        scaleY: 0.02,
        scaleX: 0.15,
        duration: SKIDOO_DURATION * ANIMATION_TIME_SCALE,
      }, base);

      // Translate the box to hit target
      timeline.add({
        targets: scene.createFormBox,
        translateX: opts.row.x,
        translateY: opts.row.y,
        opacity: 0.25,
        duration: SKIDOO_DURATION * ANIMATION_TIME_SCALE,
      }, base);

      // Reposition form and box after skidoo
      timeline.add({
        targets: scene.createForm,
        translateZ: 0,
        rotateY: 0,
        skew: 0,
        scaleY: 1,
        scaleX: 1,
        duration: 1,
      }, base + SKIDOO_DURATION * ANIMATION_TIME_SCALE);

      timeline.add({
        targets: scene.createFormBox,
        translateX: 0,
        translateY: 0,
        opacity: 0,
        duration: 1,
      }, base + SKIDOO_DURATION * ANIMATION_TIME_SCALE);
    }

    fCheck.setAttribute('opacity', '0');
    fUploadText.innerHTML = 'cv2-final-v2.pdf';

    const timeline = anime.timeline({
      easing: 'linear',
      begin: () => {
        UndrawElement(fName);
        UndrawElement(fBday);
      },
      complete: this.onAnimationComplete,
    });

    addTableResetAnimation({
      timeline,
      rows: [tRow4.el, tRow5.el, tRow6.el],
    });

    timeline.add({
      targets: [fName, fBday],
      opacity: 1,
      duration: 1,
    });
    timeline.add(DrawAnimation({ element: fName, duration: 250 }));
    timeline.add(BirthdayPlaceholderHide());
    timeline.add(DrawAnimation({ element: fBday, duration: 250 }));
    timeline.add(SetCheckmarkAnimation());
    addFileUploadAnimation({ timeline });
    timeline.add(WaitAnimation(250));
    addSkidooAnimation({
      timeline,
      row: tRow4,
    });
    addFormResetAnimation({
      timeline,
    });
    timeline.add(FormRevealAnimation());
    timeline.add(DrawAnimation({ element: fName, duration: 250 }));
    timeline.add(BirthdayPlaceholderHide());
    timeline.add(DrawAnimation({ element: fBday, duration: 250 }));
    timeline.add(SetCheckmarkAnimation());
    addFileUploadAnimation({ timeline });
    timeline.add(WaitAnimation(250));
    addSkidooAnimation({
      timeline,
      row: tRow5,
    });
    addFormResetAnimation({
      timeline,
    });
    timeline.add(FormRevealAnimation());
    timeline.add(DrawAnimation({ element: fName, duration: 250 }));
    timeline.add(BirthdayPlaceholderHide());
    timeline.add(DrawAnimation({ element: fBday, duration: 250 }));
    addFileUploadAnimation({ timeline });
    timeline.add(WaitAnimation(250));
    addSkidooAnimation({
      timeline,
      row: tRow6,
    });
    addFormResetAnimation({
      timeline,
    });
    timeline.add({
      targets: scene.createFormBox,
      opacity: 1,
      duration: 400,
    });

    return timeline;
  }

  async setStage(scene: AnimationScene) {
    makeHidden(scene.stageFormSubmit, '.create-form');
    makeHidden(scene.stageFormSubmit, '.table');

    await nextAnimationFrame();

    overlayObject(scene.stageFormSubmit, '.create-form', scene.createFormBox);
    overlayObject(scene.stageFormSubmit, '.table', scene.table);

    makeVisible(scene.createForm, '.title');
    makeVisible(scene.createForm, '.blurb');
    makeVisible(scene.createForm, '.name');
    makeVisible(scene.createForm, '.bday');
    makeVisible(scene.createForm, '.check');
    makeVisible(scene.createForm, '.upload');
    makeVisible(scene.createForm, '.submit');

    makeHidden(scene.table, '.row4');
    makeHidden(scene.table, '.row5');
    makeHidden(scene.table, '.row6');
  }

  async _setupForward(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    const transition = await makeFancyTransition([
      {
        kind: FancyTransitionKind.Translate,
        node: scene.createFormBox,
      },
      {
        kind: FancyTransitionKind.FadeIn,
        node: scene.table,
      },
    ]);

    return transition;
  }

  async _setupReverse(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    return makeFancyTransition([
      {
        kind: FancyTransitionKind.FadeIn,
        node: scene.createFormBox,
      },
      {
        kind: FancyTransitionKind.Translate,
        node: scene.table,
      },
    ]);
  }

  async _cleanup(scene: AnimationScene) {
    scene.createFormBox.style.transform = '';
    scene.createForm.style.transform = '';

    makeHidden(scene.createForm, '.name-t');
    makeHidden(scene.createForm, '.bday-t');

    makeVisible(scene.createForm, '.bday-p');
    makeHidden(scene.createForm, '.check-x');

    makeVisible(scene.createForm, '.upload-i');
    makeHidden(scene.createForm, '.upload-t');
    makeHidden(scene.createForm, '.upload-b');

    makeVisible(scene.table, '.row4');
    makeVisible(scene.table, '.row5');
    makeVisible(scene.table, '.row6');
  }
}
