import React from 'react';
import classNames from 'classnames';
import HexStyles from 'shared/ui/hex_tiles/hex_tiles.module.css';
import { Path, getPath } from 'shared/path';
import { Link } from 'react-router-dom';
import { UnreachableError } from 'shared/errors';
import { ReactComponent as Chevron } from 'shared/assets/icons/chevron.svg';

export const ConceptStyles = {
  Page: classNames('flex flex-col items-stretch min-h-full relative', HexStyles.hexfixed),
  Content: 'flex-1 min-w-[75vw] lg:min-w-0 max-w-screen-lg mx-auto py-10 px-4 bg-white border-x border-[#5747be4b]',
  PageTitle: 'text-center text-6xl font-bold text-purple-800 mb-8 lg:mb-4',
  PageBlurb: 'text-center text-2xl font-bold text-purple-800 mb-8 lg:mb-4',
  SectionForward: 'flex flex-col lg:flex-row items-center',
  SectionReverse: 'flex flex-col lg:flex-row-reverse items-center',
  TextWrapper: 'flex-1 max-w-[400px] lg:max-w-full px-4 text-center lg:text-start',
  Title: 'text-md text-purple-400 font-bold mb-2 mt-8 lg:mt-0',
  TitleHidden: 'text-md text-purple-400 font-bold mb-2 hidden lg:block',
  Main: 'text-4xl text-purple-800 font-bold mb-2',
  Blurb: 'text-md text-gray-800 mb-2',
  Graphic: 'select-none w-full max-w-[300px] lg:max-w-full',
  GraphicBig: 'select-none w-[500px] lg:w-[500px] max-w-full',
  Button: 'font-medium text-md text-center rounded-full px-5 py-2.5 select-none',
  BlueButton: 'text-white bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700',
  WhiteButton: 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600',
  NextLinkContainer: 'flex items-center border border-gray-300 rounded-md text-purple-600 hover:text-purple-400 hover:border-purple-400 my-6 py-1 px-3 md:py-2 md:px-6',
};

export function Break() {
  return <><br className='hidden lg:block'/>&#32;</>;
}

type ConceptPath =
  | Path.Concepts
  | Path.ConceptsDefinitions
  | Path.ConceptsForms
  | Path.ConceptsMechanisms;

type ConceptLinkProps = {
  kind: 'next' | 'previous';
  path: ConceptPath;
  children: React.ReactNode;
};

export function ConceptLink({ kind, path, children }: ConceptLinkProps) {
  if (kind == 'previous') {
    return (
      <Link className={ConceptStyles.NextLinkContainer} to={getPath({ kind: path })}>
        <Chevron className='h-5 w-5 mr-2 rotate-90'/>
        <div>
          <div className='text-2xl md:text-3xl text-left'>
            Previous
          </div>
          <div className='text-md text-left'>
            {children}
          </div>
        </div>
      </Link>
    );
  } else if (kind == 'next') {
    return (
      <Link className={ConceptStyles.NextLinkContainer} to={getPath({ kind: path })}>
        <div>
          <div className='text-2xl md:text-3xl text-right'>
            Next
          </div>
          <div className='text-md text-right'>
            {children}
          </div>
        </div>
        <Chevron className='h-5 w-5 ml-2 rotate-[270deg]'/>
      </Link>
    );
  } else {
    throw new UnreachableError(kind);
  }
}

export function ConceptsCallToAction() {
  return (
    <div className='mx-auto text-center pt-8 pb-16 px-4'>
      <div className='text-3xl max-w-[480px] mx-auto'>
        Check out how we can make things easier for your business
      </div>
      <div className='flex justify-center mt-5 gap-8'>
        <a href='#signup' className={classNames(ConceptStyles.Button, ConceptStyles.BlueButton)}>
          Start Building
        </a>
      </div>
    </div>
  );
}