import React from 'react';
import { ReactComponent as FormAbstract } from 'shared/assets/images/inkscape/concepts/form-abstract.svg';
import { ReactComponent as FormWidget } from 'shared/assets/images/inkscape/concepts/form-widget.svg';
import { ReactComponent as FormLinked } from 'shared/assets/images/inkscape/concepts/form-linked.svg';
import { ReactComponent as FormUnlinked } from 'shared/assets/images/inkscape/concepts/form-unlinked.svg';
import { ReactComponent as FormNested } from 'shared/assets/images/inkscape/concepts/form-nested.svg';
import { ReactComponent as WidgetTable } from 'shared/assets/images/inkscape/concepts/widget-table.svg';
import { ReactComponent as WidgetList } from 'shared/assets/images/inkscape/concepts/widget-list.svg';
import { ReactComponent as WidgetOthers } from 'shared/assets/images/inkscape/concepts/widget-collections-other.svg';
import { ReactComponent as FormDashboard } from 'shared/assets/images/inkscape/concepts/form-dashboard.svg';
import { Header } from 'shared/ui/header/header';
import { Footer } from 'shared/ui/footer/footer';
import { Path } from 'shared/path';
import { Break, ConceptLink, ConceptStyles, ConceptsCallToAction } from './concepts_base';
import { FaqAnswer, FaqItem, FaqQuestion, FaqSection } from './faq';

export const ConceptsFormsComponent = () => {
  return (
    <div className={ConceptStyles.Page}>
      <Header/>
      <div className={ConceptStyles.Content}>
        <h1 className={ConceptStyles.PageTitle}>
          Forms
        </h1>
        <p className={ConceptStyles.PageBlurb}>
          Concepts and Terminology
        </p>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              FORM
            </h3>
            <h4 className={ConceptStyles.Main}>
              Templates to show and collect data
            </h4>
            <p className={ConceptStyles.Blurb}>
              Use them for registrations, surveys, applications, orders, and more! Make a form for every step of your workflow.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <FormAbstract className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              WIDGET
            </h3>
            <h4 className={ConceptStyles.Main}>
              An individual<Break/>part of a form
            </h4>
            <p className={ConceptStyles.Blurb}>
              Combine input boxes, plain text, images, links, and buttons to create perfectly customized forms.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <FormWidget className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              LINKED FORM
            </h3>
            <h4 className={ConceptStyles.Main}>
              Forms are<Break/>linked to objects
            </h4>
            <p className={ConceptStyles.Blurb}>
              Forms automatically save and load data to their corresponding object.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <FormLinked className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              PAGE
            </h3>
            <h4 className={ConceptStyles.Main}>
              Pages are<Break/>unlinked forms.
            </h4>
            <p className={ConceptStyles.Blurb}>
              Pages are like forms but not linked to objects. They&apos;re great for making search pages, analytics dashboards, and so on.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <FormUnlinked className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              NESTED FORM
            </h3>
            <h4 className={ConceptStyles.Main}>
              Your custom forms<Break/>can also be widgets
            </h4>
            <p className={ConceptStyles.Blurb}>
              Nested forms let you create or display multiple related objects at once like a sales lead and his employer.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <FormNested className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              TABLE WIDGET
            </h3>
            <h4 className={ConceptStyles.Main}>
              Many objects<Break/>in tabular form
            </h4>
            <p className={ConceptStyles.Blurb}>
              Great for viewing and editing many objects and attributes in a simple grid
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <WidgetTable className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              LIST WIDGET
            </h3>
            <h4 className={ConceptStyles.Main}>
              A list of forms
            </h4>
            <p className={ConceptStyles.Blurb}>
              List Widgets uses a linked form as a template and combines it with a collection of objects to display a list of forms. Great to use for search results or individual to-do lists.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <WidgetList className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              OTHER COLLECTION WIDGETS
            </h3>
            <h4 className={ConceptStyles.Main}>
              Calendars, Maps, Kanban Boards
            </h4>
            <p className={ConceptStyles.Blurb}>
              Other collection widgets are like lists widgets that use linked forms as templates, often for popups when you click on things. Sometimes you can use custom forms for quick previews too.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <WidgetOthers className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              DATA ANALYSIS
            </h3>
            <h4 className={ConceptStyles.Main}>
              Pivot Tables,<Break/>Charts, and more
            </h4>
            <p className={ConceptStyles.Blurb}>
              Assemble your own company-wide dashboards and get up-to-date summaries when all your data is in one place
            </p>
          </div>
          <div className='flex-1 p-12'>
            <FormDashboard className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <FaqSection>
          <FaqItem>
            <FaqQuestion>
              Are forms for input or are they for output?
            </FaqQuestion>
            <FaqAnswer>
              Both. Forms can be purely for input like in survey or registration forms. Or they can be read-only such as confirmation forms. Or they can be somewhere in between.
            </FaqAnswer>
          </FaqItem>
          <FaqItem>
            <FaqQuestion>
              Forms can be widgets?
            </FaqQuestion>
            <FaqAnswer>
              Yes! This allows you to &quot;slot in&quot; forms into all sorts of places to create completely new things like custom list widgets. Computer scientists might know this idea by the name &quot;higher-order components,&quot; but don&apos;t let the big words scare you &mdash; it&apos;s not difficult at all, us nerds are just a little pretentious.
            </FaqAnswer>
          </FaqItem>
        </FaqSection>
        <div className='flex justify-between'>
          <ConceptLink kind='previous' path={Path.ConceptsDefinitions}>
            Definitions
          </ConceptLink>
          <ConceptLink kind='next' path={Path.ConceptsMechanisms}>
            Mechanisms
          </ConceptLink>
        </div>
        <ConceptsCallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
