import anime from 'animejs';
import {
  AnimationScene,
  ExplainerBase,
  FancyTransitionKind,
  makeFancyTransition,
  makeHidden,
  nextAnimationFrame,
  overlayObject,
} from './explainer_utils';
import { DrawArrowAnimation, RevealAnimation, WaitAnimation } from './animations';


export class ExplainerMultiform extends ExplainerBase {
  async _init(scene: AnimationScene) {
    const createPath: SVGSVGElement | null = scene.stageMultiform.querySelector('.create-path');
    const reviewPath: SVGSVGElement | null = scene.stageMultiform.querySelector('.review-path');
    const summaryPath: SVGSVGElement | null = scene.stageMultiform.querySelector('.summary-path');
    const createForm = scene.stageMultiform.querySelector('.create-form');
    const summaryForm = scene.stageMultiform.querySelector('.summary-form');

    if (
      createPath == null ||
      reviewPath == null ||
      summaryPath == null ||
      createForm == null ||
      summaryForm == null
    ) {
      return null;
    }

    const timeline = anime.timeline({
      easing: 'linear',
      complete: this.onAnimationComplete,
    });

    DrawArrowAnimation({ timeline, arrow: createPath });
    timeline.add(RevealAnimation(createForm));
    DrawArrowAnimation({ timeline, arrow: summaryPath });
    timeline.add(RevealAnimation(summaryForm));
    DrawArrowAnimation({ timeline, arrow: reviewPath });
    timeline.add(RevealAnimation(scene.reviewForm));

    return timeline;
  }

  async setStage(scene: AnimationScene) {
    makeHidden(scene.stageMultiform, '.table');
    makeHidden(scene.stageMultiform, '.review-form');

    await nextAnimationFrame();

    overlayObject(scene.stageMultiform, '.table', scene.table);
    overlayObject(scene.stageMultiform, '.review-form', scene.reviewForm);

    const createPath = scene.stageMultiform.querySelector('.create-path');
    const reviewPath = scene.stageMultiform.querySelector('.review-path');
    const summaryPath = scene.stageMultiform.querySelector('.summary-path');
    const createForm = scene.stageMultiform.querySelector('.create-form');
    const summaryForm = scene.stageMultiform.querySelector('.summary-form');
    const createPathHead = scene.stageMultiform.querySelector('.create-path .path-head');
    const reviewPathHead = scene.stageMultiform.querySelector('.review-path .path-head');
    const summaryPathHead = scene.stageMultiform.querySelector('.summary-path .path-head');

    createPath?.setAttribute('opacity', '0');
    reviewPath?.setAttribute('opacity', '0');
    summaryPath?.setAttribute('opacity', '0');
    createPathHead?.setAttribute('opacity', '0');
    reviewPathHead?.setAttribute('opacity', '0');
    summaryPathHead?.setAttribute('opacity', '0');
    createForm?.setAttribute('opacity', '0');
    summaryForm?.setAttribute('opacity', '0');
    scene.reviewForm.setAttribute('opacity', '0');
  }

  async _setupForward(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    const transition = await makeFancyTransition([
      {
        kind: FancyTransitionKind.Translate,
        node: scene.table,
      },
    ]);

    return transition;
  }

  async _setupReverse(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    const transition = await makeFancyTransition([
      {
        kind: FancyTransitionKind.FadeIn,
        node: scene.table,
      },
    ]);

    return transition;
  }

  async _cleanup(_scene: AnimationScene) {
    // pass
  }
}
