import React from 'react';
import { ReactComponent as DefinitionsTables } from 'shared/assets/images/inkscape/concepts/definitions-tables.svg';
import { ReactComponent as DefinitionsRelationships } from 'shared/assets/images/inkscape/concepts/definitions-relationships.svg';
import { ReactComponent as Object } from 'shared/assets/images/inkscape/concepts/object.svg';
import { ReactComponent as Attribute } from 'shared/assets/images/inkscape/concepts/attribute.svg';
import { ReactComponent as DataAttribute } from 'shared/assets/images/inkscape/concepts/data-attribute.svg';
import { ReactComponent as FormulaAttribute } from 'shared/assets/images/inkscape/concepts/formula-attribute.svg';
import { Header } from 'shared/ui/header/header';
import { Footer } from 'shared/ui/footer/footer';
import { Path } from 'shared/path';
import { Break, ConceptLink, ConceptStyles, ConceptsCallToAction } from './concepts_base';
import { FaqAnswer, FaqItem, FaqQuestion, FaqSection } from './faq';

export const ConceptsDefinitionsComponent = () => {
  return (
    <div className={ConceptStyles.Page}>
      <Header/>
      <div className={ConceptStyles.Content}>
        <h1 className={ConceptStyles.PageTitle}>
          Definitions
        </h1>
        <p className={ConceptStyles.PageBlurb}>
          Concepts and Terminology
        </p>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              DEFINITION
            </h3>
            <h4 className={ConceptStyles.Main}>
              A fancy name for a database table
            </h4>
            <p className={ConceptStyles.Blurb}>
              Is it a table? Is it a class? No, it&apos;s a Definition!
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <DefinitionsTables className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              OBJECT
            </h3>
            <h4 className={ConceptStyles.Main}>
              A row of a table;<Break/>
              one actual thing
            </h4>
            <p className={ConceptStyles.Blurb}>
              If &quot;Restaurant&quot; is the Definition,<Break/>then McDonald&apos;s and KFC are the Objects.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <Object className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              ATTRIBUTE
            </h3>
            <h4 className={ConceptStyles.Main}>
              Another name<Break/>for column
            </h4>
            <p className={ConceptStyles.Blurb}>
              If Definitions are tables, then Attributes are columns. There are three types: Data, Formula, and Relationship.
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <Attribute className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              DATA ATTRIBUTE
            </h3>
            <h4 className={ConceptStyles.Main}>
              An editable column
            </h4>
            <p className={ConceptStyles.Blurb}>
              Data Attributes contain values that you can read from or write to. This is just like spreadsheets!
            </p>
          </div>
          <div className='flex-1 p-12'>
            <DataAttribute className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              FORMULA ATTRIBUTE
            </h3>
            <h4 className={ConceptStyles.Main}>
              A column of<Break/>computed values
            </h4>
            <p className={ConceptStyles.Blurb}>
              Formula Attributes are read-only columns defined by code. Go beyond =SUM(A1:A9). Harness the full power of TypeScript. You tell us what to compute, we&apos;ll figure out the rest.
            </p>
          </div>
          <div className='flex-1 p-12 md:p-8 flex justify-center'>
            <FormulaAttribute className={ConceptStyles.GraphicBig} width={undefined} height={undefined}/>
          </div>
        </div>
        <div className={ConceptStyles.SectionReverse}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              RELATIONSHIP ATTRIBUTE
            </h3>
            <h4 className={ConceptStyles.Main}>
              Connections<Break/>between objects
            </h4>
            <p className={ConceptStyles.Blurb}>
              Companies have employees. Employees have employers. Every relationship attribute has an opposite.
            </p>
          </div>
          <div className='flex-1 p-12'>
            <DefinitionsRelationships className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div>
        {/* <div className={ConceptStyles.SectionForward}>
          <div className={ConceptStyles.TextWrapper}>
            <h3 className={ConceptStyles.Title}>
              DATABASE INDEX
            </h3>
            <h4 className={ConceptStyles.Main}>
              Not Yet Implemented
            </h4>
            <p className={ConceptStyles.Blurb}>
              Nobody even cares about these anyway. Except nerds. Anyway, fellow nerds, this is coming one day. #believe #trust #iwishhashtagsworked
            </p>
          </div>
          <div className='flex-1 p-12 flex justify-center'>
            <DefinitionsRelationships className={ConceptStyles.Graphic} width={undefined} height={undefined}/>
          </div>
        </div> */}
        <FaqSection>
          <FaqItem>
            <FaqQuestion>
              Why don&apos;t you just use the words &quot;Table&quot;, &quot;Row&quot;, and &quot;Column&quot;?
            </FaqQuestion>
            <FaqAnswer>
              Tables are how you visualize your data. We have &quot;Table&quot; as a widget in our form builder. It has row filters and column settings and you can make the table borders colorful and a whole lot of other nice things. We wanted to avoid confusion.
            </FaqAnswer>
          </FaqItem>
          <FaqItem>
            <FaqQuestion>
              I thought this was a No-Code tool, why do Formula Attributes require code?
            </FaqQuestion>
            <FaqAnswer>
              In our experience, long formulas are unwieldy and error-prone and every spreadsheet-based system eventually gets these. At the very least, you get to split your long if-else chains into multiple lines of code. Worst case, you can always ask ChatGPT to write small bits of code for you.
            </FaqAnswer>
          </FaqItem>
        </FaqSection>
        <div className='flex justify-between'>
          <ConceptLink kind='previous' path={Path.Concepts}>
            Overview
          </ConceptLink>
          <ConceptLink kind='next' path={Path.ConceptsForms}>
            Forms
          </ConceptLink>
        </div>
        <ConceptsCallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
