import anime from 'animejs';
import {
  ANIMATION_TIME_SCALE,
  AnimationScene,
  ExplainerBase,
  FancyTransitionKind,
  LocationXY,
  dxyToTransformPercentage,
  getElementCenter,
  makeFancyTransition,
  makeHidden,
  nextAnimationFrame,
  overlayObject,
  recenterObject,
} from './explainer_utils';


export class ExplainerWYSIWYG extends ExplainerBase {
  async _init(scene: AnimationScene) {
    const [pointerxmid, pointerymid] = getElementCenter(scene.pointer);

    const findPointerTranslation = (root: Element, selector: string, offsetY: number = 0): LocationXY | null => {
      const child = root.querySelector(selector);
      if (child == null) {
        return null;
      }
      const [dx, dy] = getElementCenter(child);

      const [x, y] = dxyToTransformPercentage(scene.pointer, dx - pointerxmid, dy - pointerymid + offsetY);

      return {
        x,
        y,
        el: child,
      };
    };

    const pText = findPointerTranslation(scene.palette, '.i-txt');
    const pInput = findPointerTranslation(scene.palette, '.i-in');
    const pDate = findPointerTranslation(scene.palette, '.i-date');
    const pCheck = findPointerTranslation(scene.palette, '.i-check');
    const pFile = findPointerTranslation(scene.palette, '.i-file');
    const pButton = findPointerTranslation(scene.palette, '.i-button');

    const fTitle = findPointerTranslation(scene.createForm, '.title', 10);
    const fBlurb = findPointerTranslation(scene.createForm, '.blurb', 10);
    const fCheck = findPointerTranslation(scene.createForm, '.check', 10);
    const fUpload = findPointerTranslation(scene.createForm, '.upload', 10);
    const fSubmit = findPointerTranslation(scene.createForm, '.submit', 10);
    const fName = findPointerTranslation(scene.createForm, '.name', 10);
    const fBday = findPointerTranslation(scene.createForm, '.bday', 10);

    if (
      pText == null ||
      pInput == null ||
      pDate == null ||
      pCheck == null ||
      pFile == null ||
      pButton == null ||
      fTitle == null ||
      fBlurb == null ||
      fName == null ||
      fBday == null ||
      fCheck == null ||
      fUpload == null ||
      fSubmit == null
    ) {
      return null;
    }

    const PALETTE_MOVE_DURATION = 250;
    const PALETTE_MOVE_BACKSWING = 150;

    const FORM_MOVE_DURATION = 300;
    const FORM_MOVE_BACKSWING = 150;

    const FORM_REVEAL_DELAY = 300;

    function toPalette(target: LocationXY) {
      return {
        targets: scene.pointer,
        translateX: target.x,
        translateY: target.y,
        duration: PALETTE_MOVE_DURATION * ANIMATION_TIME_SCALE,
        endDelay: PALETTE_MOVE_BACKSWING * ANIMATION_TIME_SCALE,
      };
    }

    function toForm(target: LocationXY) {
      return {
        targets: scene.pointer,
        translateX: target.x,
        translateY: target.y,
        duration: FORM_MOVE_DURATION * ANIMATION_TIME_SCALE,
        endDelay: FORM_MOVE_BACKSWING * ANIMATION_TIME_SCALE,
      };
    }

    function revealWidget(target: LocationXY) {
      return {
        targets: target.el,
        opacity: 1,
        duration: FORM_REVEAL_DELAY * ANIMATION_TIME_SCALE,
      };
    }

    const timeline = anime.timeline({
      easing: 'easeInOutSine',
      complete: this.onAnimationComplete,
    });

    timeline.add({
      translateX: pText.x,
      translateY: pText.y,
      duration: 1,
      begin: () => {
        scene.pointer.style.visibility = 'visible';
      },
    });

    timeline.add({
      targets: [
        fTitle.el,
        fBlurb.el,
        fName.el,
        fBday.el,
        fCheck.el,
        fUpload.el,
        fSubmit.el,
      ],
      opacity: 0,
      duration: 1,
    });

    timeline.add(toPalette(pText));
    timeline.add(toForm(fTitle));
    timeline.add(revealWidget(fTitle), timeline.duration - FORM_REVEAL_DELAY);
    timeline.add(toPalette(pText));
    timeline.add(toForm(fBlurb));
    timeline.add(revealWidget(fBlurb), timeline.duration - FORM_REVEAL_DELAY);
    timeline.add(toPalette(pInput));
    timeline.add(toForm(fName));
    timeline.add(revealWidget(fName), timeline.duration - FORM_REVEAL_DELAY);
    timeline.add(toPalette(pDate));
    timeline.add(toForm(fBday));
    timeline.add(revealWidget(fBday), timeline.duration - FORM_REVEAL_DELAY);
    timeline.add(toPalette(pCheck));
    timeline.add(toForm(fCheck));
    timeline.add(revealWidget(fCheck), timeline.duration - FORM_REVEAL_DELAY);
    timeline.add(toPalette(pFile));
    timeline.add(toForm(fUpload));
    timeline.add(revealWidget(fUpload), timeline.duration - FORM_REVEAL_DELAY);
    timeline.add(toPalette(pButton));
    timeline.add(toForm(fSubmit));
    timeline.add(revealWidget(fSubmit), timeline.duration - FORM_REVEAL_DELAY);

    timeline.add({
      targets: scene.pointer,
      translateX: fSubmit.x,
      translateY: fSubmit.y,
      opacity: 0,
      duration: 200,
    });

    return timeline;
  }

  async setStage(scene: AnimationScene) {
    makeHidden(scene.stageWYSIWYG, '.palette');
    makeHidden(scene.stageWYSIWYG, '.create-form');

    await nextAnimationFrame();

    recenterObject(scene.stageWYSIWYG, '.i-txt', scene.pointer);
    overlayObject(scene.stageWYSIWYG, '.palette', scene.palette);
    overlayObject(scene.stageWYSIWYG, '.create-form', scene.createFormBox);

    makeHidden(scene.createForm, '.title');
    makeHidden(scene.createForm, '.blurb');
    makeHidden(scene.createForm, '.name');
    makeHidden(scene.createForm, '.bday');
    makeHidden(scene.createForm, '.check');
    makeHidden(scene.createForm, '.upload');
    makeHidden(scene.createForm, '.submit');
  }

  async _setupForward(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    scene.pointer.style.visibility = 'hidden';
    return makeFancyTransition([
      {
        kind: FancyTransitionKind.FadeIn,
        node: scene.pointer,
      },
    ]);
  }

  async _setupReverse(scene: AnimationScene): Promise<anime.AnimeTimelineInstance | null> {
    this.setStage(scene);

    scene.pointer.style.visibility = 'hidden';
    return makeFancyTransition([
      {
        kind: FancyTransitionKind.FadeIn,
        node: scene.pointer,
      },
      {
        kind: FancyTransitionKind.FadeIn,
        node: scene.palette,
      },
      {
        kind: FancyTransitionKind.Translate,
        node: scene.createFormBox,
      },
    ]);
  }

  async _cleanup(scene: AnimationScene) {
    scene.pointer.style.opacity = '0';
    scene.pointer.style.transform = '';
  }
}
