import { UnreachableError } from './errors';

export enum Path {
  Home = 'Home',
  Pricing = 'Pricing',
  About = 'About',
  Concepts = 'Concepts',
  ConceptsDefinitions = 'ConceptsDefinitions',
  ConceptsForms = 'ConceptsForms',
  ConceptsMechanisms = 'ConceptsMechanisms',
}

export type PathArguments =
  | { kind: Path.Home }
  | { kind: Path.Pricing }
  | { kind: Path.About }
  | { kind: Path.Concepts }
  | { kind: Path.ConceptsDefinitions }
  | { kind: Path.ConceptsForms }
  | { kind: Path.ConceptsMechanisms };

export function getPath(args: PathArguments) {
  switch(args.kind) {
    case Path.Home:
      return '/';
    case Path.Pricing:
      return '/pricing/';
    case Path.About:
      return '/about/';
    case Path.Concepts:
      return '/concepts/';
    case Path.ConceptsDefinitions:
      return '/concepts/definitions/';
    case Path.ConceptsForms:
      return '/concepts/forms/';
    case Path.ConceptsMechanisms:
      return '/concepts/mechanisms/';
    default:
      throw new UnreachableError(args);
  }
}
