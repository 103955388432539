import React from 'react';
import { onEffectResizeObserver } from 'shared/hooks';
import classNames from 'classnames';
import Styles from './hex_tiles.module.css';

const FRAMES_PER_SECOND = 20;

const HEXAGON_RADIUS = 50;
const HEXAGON_SIN = [0, 1, 2, 3, 4, 5].map(i => Math.sin(Math.PI * i / 3));
const HEXAGON_COS = [0, 1, 2, 3, 4, 5].map(i => Math.cos(Math.PI * i / 3));

const START_Y = HEXAGON_RADIUS * (0.4 - HEXAGON_SIN[1]);
const DELTA_Y = HEXAGON_RADIUS * HEXAGON_SIN[1];
const START_X_ODD = HEXAGON_RADIUS * 0.2;
const START_X_EVEN = HEXAGON_RADIUS * (1.2 + HEXAGON_COS[1]);
const DELTA_X = HEXAGON_RADIUS * (2 + 2 * HEXAGON_COS[1]);

const MAX_DISTANCE = 225;
const MAX_DISTANCE_SQUARED = MAX_DISTANCE * MAX_DISTANCE;
const MAX_SHRINK = 0.30;

const DELTA_WEIGHT = 1.5 / FRAMES_PER_SECOND;
const EPSILON_WEIGHT = 0.000001;

type Hexagon = {
  x: number;
  y: number;
  upward: boolean;
  active: boolean;
  target: number;
  weight: number;
  floor: number;
};

type MouseTracker = {
  mx: number;
  my: number;
  sx: number;
  sy: number;
};

function getHexagonTarget(hypot2: number): number {
  // If you want a linear map from [0 to 1] to [0 to MAX_DISTANCE], you need to sqrt(hypot^2 / max_dist^2)
  // But I like Math.pow(hypot2 / MAX_DISTANCE_SQUARED, 3/4). This makes me feel good.
  // This is purely gut-feel optimized. I just think Math.sqrt is like 10x faster than Math.pow(float, float)
  // and two multiplications should be next-to-nothing compared to those operations
  const quadrt = Math.sqrt(Math.sqrt(hypot2 / MAX_DISTANCE_SQUARED));
  return 1 - quadrt * quadrt * quadrt;
}

function getHexagonScale(weight: number) {
  return 1 - MAX_SHRINK * weight;
}

function getHexagonWidth(weight: number) {
  return 1 + weight * 2;
}

function getHexagonColor(weight: number) {
  return `rgb(87, 71, 190, ${0.15 + 0.20 * weight})`;
}

function hexagonResize(canvas: HTMLCanvasElement) {
  canvas.width = canvas.offsetWidth;
  canvas.height = canvas.offsetHeight;
}

function hexagonCreate(width: number, height: number): Hexagon[] {
  const hexes: Hexagon[] = [];
  // Create one hexagon tile for debugging
  // hexes.push({
  //   x: 300,
  //   y: 200,
  //   upward: false,
  //   active: false,
  //   target: 0,
  //   weight: 0,
  //   floor: 0,
  // });
  for (let y = START_Y, odd = 1; y - DELTA_Y < height; y += DELTA_Y, odd = (odd + 1) % 2) {
    for (let x = odd ? START_X_ODD : START_X_EVEN; x - HEXAGON_RADIUS * HEXAGON_COS[0] < width; x += DELTA_X) {
      hexes.push({
        x,
        y,
        upward: false,
        active: false,
        target: 0,
        weight: 0,
        floor: 0,
      });
    }
  }
  return hexes;
}

function hexagonDrawSingle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  weight: number,
): boolean {
  ctx.shadowBlur = 16;
  ctx.shadowColor = 'rgb(87, 71, 190, 1)';
  ctx.strokeStyle = getHexagonColor(weight);
  ctx.lineWidth = getHexagonWidth(weight);
  const scale = getHexagonScale(weight);
  ctx.beginPath();
  ctx.moveTo(x + scale * HEXAGON_RADIUS, y);
  for (let i = 1; i < 6; i++) {
    ctx.lineTo(x + scale * HEXAGON_RADIUS * HEXAGON_COS[i], y + scale * HEXAGON_RADIUS * HEXAGON_SIN[i]);
  }
  // ctx.textAlign = 'center';
  // ctx.fillText(`${Math.round(hex.weight * 100) / 100}`, x, y + 5);
  ctx.closePath();
  ctx.stroke();
  return true;
}

function hexagonDrawAll(canvas: HTMLCanvasElement, hexes: Hexagon[]) {
  const ctx = canvas.getContext('2d');

  if (ctx == null) {
    return;
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  for (const hex of hexes) {
    hexagonDrawSingle(ctx, hex.x, hex.y, hex.weight);
  }
}

function hexagonTick(hexes: Hexagon[]): boolean {
  // Applies one tick of animation. Returns false if the hexagons are stable

  let changed = false;
  for (const hex of hexes) {
    if (hex.upward) {
      const delta = hex.target - hex.weight;
      hex.weight = Math.min(hex.target, hex.weight + DELTA_WEIGHT);
      if (delta > EPSILON_WEIGHT) {
        // If the weight changed a lot, then this tick changed something
        changed = true;
      } else {
        // If the weight didn't change much, then it must have hit the target
        // This is a valid change for this tick as well
        changed = true;
        hex.upward = false;
        hex.target = hex.floor;
      }
    } else {
      // Otherwise we are going downward
      // In that case, we need to go down to the floor
      // which should be zero for inactive and distance-based for active
      const delta = hex.weight - hex.target;
      hex.weight = Math.max(hex.floor, hex.weight - DELTA_WEIGHT);

      if (delta > EPSILON_WEIGHT) {
        // Keep shrinking
        changed = true;
      }
    }
  }

  return changed;
}

type HexStyleProps = {
  fixed?: boolean;
};

export const HexTiles = ({ fixed }: HexStyleProps) => {
  const [canvas, setCanvas] = React.useState<HTMLCanvasElement>();
  const interval = React.useRef<NodeJS.Timer>();
  const mousetrack = React.useRef<MouseTracker>({
    mx: 0,
    my: 0,
    sx: 0,
    sy: 0,
  });
  const hexagons = React.useRef<Hexagon[]>();

  const renderHexagonFrame = React.useCallback(() => {
    if (canvas == null || hexagons.current == null) {
      return;
    }
    hexagonDrawAll(canvas, hexagons.current);
  }, [canvas]);

  const startHexagonLoop = React.useCallback(() => {
    if (interval.current) {
      return;
    }

    // Run an interval loop that recalculates positions FPS times per second
    interval.current = setInterval(() => {
      let changed = false;
      if (hexagons.current != null) {
        // Do a calculation tick, then on the next animation frame, redraw all the lines
        changed = hexagonTick(hexagons.current);
        requestAnimationFrame(() => {
          if (canvas == null || hexagons.current == null) {
            return;
          }
          hexagonDrawAll(canvas, hexagons.current);
        });
      }

      // If nothing has changed on the calculation tick, stop the loop
      if (!changed) {
        clearInterval(interval.current);
        interval.current = undefined;
      }
    }, 1000 / FRAMES_PER_SECOND);
  }, [canvas]);

  const onMountCanvas = React.useCallback((refCanvas: HTMLCanvasElement) => {
    if (canvas != refCanvas) {
      setCanvas(refCanvas);
    }
  }, []);

  React.useEffect(() => {
    if (canvas == null) {
      return;
    }

    // Recalculate hexagons on mount
    hexagonResize(canvas);
    hexagons.current = hexagonCreate(canvas.width, canvas.height);
    renderHexagonFrame();

    const observerCleanup = onEffectResizeObserver(canvas, () => {
      // Recalculate hexagons on resize
      hexagonResize(canvas);
      hexagons.current = hexagonCreate(canvas.width, canvas.height);
      renderHexagonFrame();
    });

    const updateHexagonTargets = (mx: number, my: number, crect: DOMRect, hexes: Hexagon[]): boolean => {
      if (crect.bottom < 0) {
        return false;
      }

      for (const hex of hexes) {
        const dx = hex.x - mx;
        const dy = hex.y - my;
        // No sqrt for optimization
        const hypot2 = dx * dx + dy * dy;
        if (hypot2 < MAX_DISTANCE_SQUARED) {
          // A hexagon is active whenever it's within the correct squared distance
          hex.active = true;

          // The target weight depends on euclidean distance
          const target = getHexagonTarget(hypot2);
          // The hexagon is not allowed to shrink below this value
          hex.floor = target;

          // We update the target whenever...
          if (hex.upward && target > hex.target) {
            // ...the hexagon is already growing and the target value is going to get bigger
            hex.target = target;
          } else if (!hex.upward && target > hex.weight) {
            // ...the hexagon is shrinking and the target value is bigger than our current size
            hex.target = target;
            // In this case, we also mark the hexagon as growing
            hex.upward = true;
          }
        } else {
          hex.active = false;
          hex.floor = 0;
        }
      }
      return true;
    };

    const onMouseMove = (event: MouseEvent) => {
      if (canvas == null || hexagons.current == null) {
        return;
      }

      const bounds = canvas.getBoundingClientRect();
      const mx = event.clientX - bounds.left;
      const my = event.clientY - bounds.top;

      mousetrack.current.mx = mx;
      mousetrack.current.my = my;
      mousetrack.current.sx = window.scrollX;
      mousetrack.current.sy = window.scrollY;

      const changed = updateHexagonTargets(mx, my, bounds, hexagons.current);
      if (changed) {
        startHexagonLoop();
      }
    };

    const onTouchMove = (event: TouchEvent) => {
      if (canvas == null || hexagons.current == null) {
        return;
      }

      const bounds = canvas.getBoundingClientRect();
      const mx = event.touches[0].clientX - bounds.left;
      const my = event.touches[0].clientY - bounds.top;

      mousetrack.current.mx = mx;
      mousetrack.current.my = my;
      mousetrack.current.sx = window.scrollX;
      mousetrack.current.sy = window.scrollY;

      const changed = updateHexagonTargets(mx, my, bounds, hexagons.current);
      if (changed) {
        startHexagonLoop();
      }
    };

    const onTouchEnd = () => {
      if (canvas == null || hexagons.current == null) {
        return;
      }

      const bounds = canvas.getBoundingClientRect();
      const mx = Number.NEGATIVE_INFINITY;
      const my = Number.NEGATIVE_INFINITY;

      mousetrack.current.mx = mx;
      mousetrack.current.my = my;
      mousetrack.current.sx = window.scrollX;
      mousetrack.current.sy = window.scrollY;

      const changed = updateHexagonTargets(mx, my, bounds, hexagons.current);
      if (changed) {
        startHexagonLoop();
      }
    };

    const onDocumentScroll = () => {
      if (canvas == null || hexagons.current == null || fixed) {
        return;
      }

      const bounds = canvas.getBoundingClientRect();
      const mx = mousetrack.current.mx + window.scrollX - mousetrack.current.sx;
      const my = mousetrack.current.my + window.scrollY - mousetrack.current.sy;

      const changed = updateHexagonTargets(mx, my, bounds, hexagons.current);
      if (changed) {
        startHexagonLoop();
      }
    };

    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchstart', onTouchMove);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('scroll', onDocumentScroll);

    // eslint-disable-next-line consistent-return
    return () => {
      observerCleanup();
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchstart', onTouchMove);
      document.removeEventListener('click', onMouseMove);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('scroll', onDocumentScroll);
      if (interval.current != null) {
        clearInterval(interval.current);
      }
    };
  }, [canvas]);


  return (
    <canvas className={classNames(Styles.canvas, fixed && Styles.fixed)} ref={onMountCanvas}/>
  );
};
