import React from 'react';
import classNames from 'classnames';
import Styles from './animated.module.css';

export const AnimatedIsActive = React.createContext(false);

type AnimatedProps = {
  children: React.ReactNode;
  fadeIn?: boolean,
  slideLeft?: boolean;
  slideRight?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function Animated({
  fadeIn,
  slideLeft,
  slideRight,
  children,
  ...props
}: AnimatedProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = React.useState(false);
  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (!isActive && entry.isIntersecting) {
      setIsActive(entry.isIntersecting);
    }
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.33,
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      {...props}
      className={classNames(
        props.className,
        Styles.animated,
        fadeIn && Styles.fadeIn,
        slideLeft && Styles.slideLeft,
        slideRight && Styles.slideRight,
        isActive && Styles.active,
      )}
      ref={ref}
    >
      <AnimatedIsActive.Provider value={isActive}>
        {children}
      </AnimatedIsActive.Provider>
    </div>
  );
}
