import React from 'react';
import classNames from 'classnames';
import { Footer } from 'shared/ui/footer/footer';
import { Header } from 'shared/ui/header/header';
import { ReactComponent as FormAbstract } from 'shared/assets/images/inkscape/concepts/form-abstract.svg';
import { ReactComponent as Check } from 'shared/assets/icons/check.svg';
import { ReactComponent as InternalApp } from 'shared/assets/images/inkscape/concepts/internal-app.svg';
import { ReactComponent as StandaloneForm } from 'shared/assets/images/inkscape/concepts/standalone-form.svg';
import { ReactComponent as EmbedForm } from 'shared/assets/images/inkscape/concepts/embed-form.svg';
import { Link } from 'react-router-dom';
import { Animated, AnimatedIsActive } from 'shared/ui/animated';
import { Explainer } from './explainer/explainer';
import { HeroComponent } from './hero/hero';
import styles from './home.module.css';

const Tailwind = {
  Button: 'font-medium text-md text-center rounded-full px-5 py-2.5 select-none',
  BlueButton: 'text-white bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700',
  WhiteButton: 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600',
  SectionTitle: 'text-3xl text-center text-purple-500',
  SectionBlurbSmall: 'block md:hidden text-xl text-center text-purple-500 mt-4 mb-2',
  SectionBlurbLarge: 'hidden md:block text-lg text-center text-purple-800 mt-1',
  SectionBlurb: 'text-lg text-center text-purple-800 mt-1',
};

function Divider() {
  return (
    <hr className='border-b-gray-300 border-b-[1px]'/>
  );
}


function BuiltDifferent() {
  return (
    <div className={classNames('pt-[80px] pb-[60px] px-8', styles.outer)}>
      <Animated fadeIn slideRight className='max-w-screen-sm mx-auto'>
        <h3 className={Tailwind.SectionTitle}>
          Your business is different...
        </h3>
        <p className='text-lg text-center text-purple-800 mt-1'>
          You&apos;ve looked at all the different tools out there, and nothing fits your workflows perfectly,
          so you assemble a mix of tools and live with that.
        </p>
      </Animated>
    </div>
  );
}

function BuiltDifferent2() {
  return (
    <div className={classNames('pt-[80px] pb-[60px] px-8', styles.outer)}>
      <Animated fadeIn slideLeft className='max-w-screen-sm mx-auto'>
        <h3 className={Tailwind.SectionTitle}>
          ...but why not make your own?
        </h3>
        <p className='text-lg text-center text-purple-800 mt-1'>
          Most business software are just heaps of forms with some automation thrown in. With Mechaform, you can just do it yourself!
        </p>
      </Animated>
    </div>
  );
}

type WorkflowItemProps = {
  children: React.ReactNode;
};

function WorkflowItem({ children }: WorkflowItemProps) {
  return (
    <li className='flex items-center justify-start mb-2'>
      <div className='inline-flex items-center justify-center bg-purple-400 rounded-full p-1 mr-1'>
        <Check className='text-white' width='0.5rem' height='0.5rem'/>
      </div>
      <span className='text-md md:text-lg'>
        {children}
      </span>
    </li>
  );
}


function MultipleWorkflows() {
  return (
    <Animated fadeIn slideLeft className='bg-purple-200 pt-[80px] pb-[60px] px-8 relative z-10'>
      <div className='max-w-screen-md mx-auto'>
        <h3 className={Tailwind.SectionTitle}>
          Adapts to every workflow
        </h3>
        <h6 className={Tailwind.SectionBlurbLarge}>
          Bring your whole team together
        </h6>
        <div className='mt-4 flex flex-col md:flex-row md:justify-center md:items-start'>
          <FormAbstract className='flex-[3] max-w-xl mx-auto md:my-6 select-none' width={undefined} height={undefined}/>
          <div className='text-purple-800 md:flex-[2] md:ml-8 md:mt-10'>
            <h6 className={Tailwind.SectionBlurbSmall}>
              Bring your whole team together
            </h6>
            <ul className='list-none pl-4'>
              <WorkflowItem>
                Asset Management
              </WorkflowItem>
              <WorkflowItem>
                Applicant Tracking
              </WorkflowItem>
              <WorkflowItem>
                Ticketing Systems
              </WorkflowItem>
              <WorkflowItem>
                CRMs
              </WorkflowItem>
            </ul>
          </div>
        </div>
      </div>
    </Animated>
  );
}

type FlexibleColumnProps = {
  column: string;
  image: React.ReactNode;
  title: React.ReactNode;
  blurb: React.ReactNode;
};

function FlexibleColumn({ column, image, title, blurb }: FlexibleColumnProps): React.ReactElement {
  return (
    <>
      <div className={classNames('', styles.image, column, 'mx-4 mt-8 first:mt-0 md:mt-0')}>
        {image}
      </div>
      <div className={classNames(styles.title, column, 'text-xl md:text-lg text-center font-semibold text-purple-600 mt-2')}>
        {title}
      </div>
      <div className={classNames(styles.blurb, column, 'text-md text-center text-purple-800 mt-2')}>
        {blurb}
      </div>
    </>
  );
}

function FlexibleDeployment() {
  return (
    <Animated fadeIn slideRight className='max-w-screen-md mx-auto pt-[80px] pb-[60px] px-4'>
      <div className='text-center mb-6'>
        <div className={Tailwind.SectionTitle}>
          Flexible Setups
        </div>
        <div className={Tailwind.SectionBlurb}>
          Deploy forms in different ways
        </div>
      </div>
      <div className={styles.flexible}>
        <FlexibleColumn
          column={styles.internal}
          image={<InternalApp className='w-full h-full select-none' width={undefined} height={undefined}/>}
          title={<>Internal Applications</>}
          blurb={<>Build custom internal workflows without code</>}
        />
        <FlexibleColumn
          column={styles.alone}
          image={<StandaloneForm className='w-full h-full select-none' width={undefined} height={undefined}/>}
          title={<>Standalone Pages</>}
          blurb={<>Useful for surveys, contact forms, and more</>}
        />
        <FlexibleColumn
          column={styles.embed}
          image={<EmbedForm className='w-full h-full select-none' width={undefined} height={undefined}/>}
          title={<>HTML Embeds</>}
          blurb={<>Embed forms into your existing websites</>}
        />
      </div>
    </Animated>
  );
}

function CallToAction() {
  return (
    <div className='max-w-screen-md mx-auto text-center pt-8 pb-16 px-4'>
      <div className='text-3xl'>
        Sign up now
      </div>
      <div className='mt-1 mb-5 text-l'>
        Be an automation wizard
      </div>
      <div className='flex justify-center mt-5 gap-8'>
        <a href='#signup' className={classNames(Tailwind.Button, Tailwind.BlueButton)}>
          Start Building
        </a>
        <Link to='/concepts' className={classNames(Tailwind.Button, Tailwind.WhiteButton)}>
          Learn more
        </Link>
      </div>
    </div>
  );
}

export const HomeComponent = () => {
  return (
    <div className='flex flex-col items-stretch h-full'>
      <Header/>
      <div className='flex-1'>
        <HeroComponent/>
        <BuiltDifferent/>
        <BuiltDifferent2/>
        <Animated fadeIn>
          <AnimatedIsActive.Consumer>
            {(isActive) => <Explainer isActive={isActive}/>}
          </AnimatedIsActive.Consumer>
        </Animated>
        <MultipleWorkflows/>
        <FlexibleDeployment/>
        <Divider/>
        <CallToAction/>
      </div>
      <Footer/>
    </div>
  );
};
