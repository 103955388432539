import React from 'react';
import { ReactComponent as SceneWYSIWYG } from 'shared/assets/images/inkscape/explainer/scene1-wysiwyg.svg';
import { ReactComponent as SceneFormSubmit } from 'shared/assets/images/inkscape/explainer/scene2-form-submit.svg';
import { ReactComponent as SceneMultiform } from 'shared/assets/images/inkscape/explainer/scene3-multiform.svg';
import { ReactComponent as SceneMechanism } from 'shared/assets/images/inkscape/explainer/scene4-mechanism.svg';
import { ReactComponent as Pointer } from 'shared/assets/images/inkscape/explainer/cursor-pointer.svg';
import { ReactComponent as CreateForm } from 'shared/assets/images/inkscape/explainer/create-form.svg';
import { ReactComponent as ReviewForm } from 'shared/assets/images/inkscape/explainer/review-form.svg';
import { ReactComponent as Table } from 'shared/assets/images/inkscape/explainer/table.svg';
import { ReactComponent as Palette } from 'shared/assets/images/inkscape/explainer/palette.svg';
import { ReactComponent as ReplayIcon } from 'shared/assets/icons/replay.svg';
import { ReactComponent as ChevronIcon } from 'shared/assets/icons/chevron.svg';
import { useOnce } from 'shared/hooks';
import classNames from 'classnames';
import { UnreachableError } from 'shared/errors';
import { ExplainerWYSIWYG } from './explainer_wysiwyg';
import { ExplainerFormSubmit } from './explainer_form_submit';
import { ExplainerMultiform } from './explainer_multiform';
import { ExplainerMechanism } from './explainer_mechanism';
import { AnimationScene, ExplainerBase } from './explainer_utils';
import Styles from './explainer.module.css';

enum AnimationStep {
  WYSIWYG = 'WYSIWYG',
  FormSubmit = 'FormSubmit',
  Multiform = 'Multiform',
  Mechanism = 'Mechanism',
}

function makeAnimator(step: AnimationStep, onAnimationComplete: () => void): ExplainerBase {
  if (step === AnimationStep.WYSIWYG) {
    return new ExplainerWYSIWYG(onAnimationComplete);
  } else if (step === AnimationStep.FormSubmit) {
    return new ExplainerFormSubmit(onAnimationComplete);
  } else if (step === AnimationStep.Multiform) {
    return new ExplainerMultiform(onAnimationComplete);
  } else if (step === AnimationStep.Mechanism) {
    return new ExplainerMechanism(onAnimationComplete);
  } else {
    throw new UnreachableError(step);
  }
}

const ANIMATION_STEP_ORDER = [
  AnimationStep.WYSIWYG,
  AnimationStep.FormSubmit,
  AnimationStep.Multiform,
  AnimationStep.Mechanism,
];

function findPrevAnimationStep(step: AnimationStep): AnimationStep | null {
  const index = ANIMATION_STEP_ORDER.indexOf(step);
  if (index - 1 >= 0) {
    return ANIMATION_STEP_ORDER[index - 1];
  }
  return null;
}

function findNextAnimationStep(step: AnimationStep): AnimationStep | null {
  const index = ANIMATION_STEP_ORDER.indexOf(step);
  if (index + 1 < ANIMATION_STEP_ORDER.length) {
    return ANIMATION_STEP_ORDER[index + 1];
  }
  return null;
}

type SceneFlags = Record<keyof AnimationScene, boolean>;

const SceneFlags: Record<AnimationStep, SceneFlags> = {
  [AnimationStep.WYSIWYG]: {
    explainer: true,
    stageWYSIWYG: true,
    stageFormSubmit: false,
    stageMechanism: false,
    stageMultiform: false,
    palette: true,
    pointer: true,
    createFormBox: true,
    createForm: true,
    reviewForm: false,
    table: false,
  },
  [AnimationStep.FormSubmit]: {
    explainer: true,
    stageWYSIWYG: false,
    stageFormSubmit: true,
    stageMechanism: false,
    stageMultiform: false,
    palette: false,
    pointer: false,
    createFormBox: true,
    createForm: true,
    reviewForm: false,
    table: true,
  },
  [AnimationStep.Multiform]: {
    explainer: true,
    stageWYSIWYG: false,
    stageFormSubmit: false,
    stageMechanism: true,
    stageMultiform: false,
    palette: false,
    pointer: false,
    createFormBox: false,
    createForm: false,
    reviewForm: true,
    table: true,
  },
  [AnimationStep.Mechanism]: {
    explainer: true,
    stageWYSIWYG: false,
    stageFormSubmit: false,
    stageMechanism: false,
    stageMultiform: true,
    palette: false,
    pointer: false,
    createFormBox: false,
    createForm: false,
    reviewForm: true,
    table: false,
  },
};

type ExplainerProgressProps = {
  step: AnimationStep;
  prevStep(): void;
  nextStep(): void;
};

type ExplainerPipProps = {
  step: AnimationStep;
  curr: AnimationStep;
};

function ExplainerPip({ step, curr }: ExplainerPipProps) {
  const iStep = ANIMATION_STEP_ORDER.indexOf(step);
  const iCurr = ANIMATION_STEP_ORDER.indexOf(curr);
  if (iStep < iCurr) {
    return (
      <div className='flex-1'>
        <div className='h-3.5 w-3.5 bg-purple-400 opacity-70 rounded-full'/>
      </div>
    );
  } else if (iStep == iCurr) {
    return (
      <div className='flex-1'>
        <div className='h-[1.125rem] w-[1.125rem] bg-purple-400 rounded-full'/>
      </div>
    );
  } else {
    return (
      <div className='flex-1'>
        <div className='h-3.5 w-3.5 bg-gray-300 rounded-full'/>
      </div>
    );
  }
}

function ExplainerProgress({ step, prevStep, nextStep }: ExplainerProgressProps) {
  return (
    <>
      <button
        type='button'
        className='flex items-center text-purple-600 hover:text-purple-400 disabled:text-gray-300 p-2 mr-3'
        onClick={prevStep}
        disabled={step === AnimationStep.WYSIWYG}
      >
        <ChevronIcon className='h-5 w-5 rotate-[90deg]'/>
      </button>
      <div className='flex items-center gap-3'>
        {ANIMATION_STEP_ORDER.map(s => {
          return <ExplainerPip key={s} curr={step} step={s}/>;
        })}
      </div>
      <button
        type='button'
        className='flex items-center text-purple-600 hover:text-purple-400 disabled:text-gray-300 p-2 ml-3'
        onClick={nextStep}
        disabled={step === AnimationStep.Mechanism}
      >
        <ChevronIcon className='h-5 w-5 rotate-[270deg]'/>
      </button>
    </>
  );
}

type ExplainerProps = {
  isActive: boolean;
};

export function Explainer({ isActive }: ExplainerProps) {
  const explainer = React.useRef<HTMLDivElement>(null);
  const stageWYSIWYG = React.useRef<SVGSVGElement>(null);
  const stageFormSubmit = React.useRef<SVGSVGElement>(null);
  const stageMultiform = React.useRef<SVGSVGElement>(null);
  const stageMechanism = React.useRef<SVGSVGElement>(null);
  const palette = React.useRef<SVGSVGElement>(null);
  const pointer = React.useRef<SVGSVGElement>(null);
  const createFormBox = React.useRef<HTMLDivElement>(null);
  const createForm = React.useRef<SVGSVGElement>(null);
  const reviewForm = React.useRef<SVGSVGElement>(null);
  const table = React.useRef<SVGSVGElement>(null);
  const [step, setStep] = React.useState<AnimationStep>(AnimationStep.WYSIWYG);
  const [animator, setAnimator] = React.useState<ExplainerBase | null>(null);
  const [playing, setPlaying] = React.useState(false);

  const [scene, setScene] = React.useState<AnimationScene | null>(null);

  const onAnimationComplete = React.useCallback(() => {
    setPlaying(false);
  }, []);

  useOnce((() => {
    if (
      explainer.current == null ||
      stageWYSIWYG.current == null ||
      stageFormSubmit.current == null ||
      stageMultiform.current == null ||
      stageMechanism.current == null ||
      pointer.current == null ||
      palette.current == null ||
      createFormBox.current == null ||
      createForm.current == null ||
      reviewForm.current == null ||
      table.current == null
    ) {
      return;
    }

    const newScene: AnimationScene = {
      explainer: explainer.current,
      stageWYSIWYG: stageWYSIWYG.current,
      stageFormSubmit: stageFormSubmit.current,
      stageMultiform: stageMultiform.current,
      stageMechanism: stageMechanism.current,
      pointer: pointer.current,
      palette: palette.current,
      createFormBox: createFormBox.current,
      createForm: createForm.current,
      reviewForm: reviewForm.current,
      table: table.current,
    };
    setScene(newScene);

    const anim = makeAnimator(AnimationStep.WYSIWYG, onAnimationComplete);
    setAnimator(anim);
  }) as React.EffectCallback, []);

  React.useEffect(() => {
    if (isActive && scene) {
      setPlaying(true);
      animator?.startForward(scene);
    }
  }, [isActive]);

  const prevStep = React.useCallback(async() => {
    if (scene == null) {
      return;
    }

    // Clean up the current animation
    if (animator != null) {
      animator.skip(scene);
    }

    const prev = findPrevAnimationStep(step);
    if (prev != null) {
      const prevAnimator = makeAnimator(prev, onAnimationComplete);
      setStep(prev);
      setAnimator(prevAnimator);
      setPlaying(true);
      prevAnimator.startReverse(scene);
    } else {
      console.warn("Can't go prev!");
    }
  }, [scene, step, animator]);

  const replayAnimation = React.useCallback(async() => {
    if (animator != null) {
      setPlaying(true);
      animator.replay();
    }
  }, [animator]);

  const nextStep = React.useCallback(async() => {
    if (scene == null) {
      return;
    }

    // Clean up the current animation
    if (animator != null) {
      animator.skip(scene);
    }
    const next = findNextAnimationStep(step);
    if (next != null) {
      const nextAnimator = makeAnimator(next, onAnimationComplete);
      setStep(next);
      setAnimator(nextAnimator);
      setPlaying(true);
      nextAnimator.startForward(scene);
    } else {
      console.warn("Can't go next!");
    }
  }, [scene, step, animator]);

  const flags = SceneFlags[step];

  const sceneItem = (hidden: boolean, ...classes: string[]): string => {
    return classNames(classes, hidden || 'hidden');
  };

  const messages: Record<AnimationStep, string> = {
    [AnimationStep.WYSIWYG]: 'Make a form by drag and drop',
    [AnimationStep.FormSubmit]: 'Submit forms to save data',
    [AnimationStep.Multiform]: 'Use different forms for different steps',
    [AnimationStep.Mechanism]: 'Let computers do the boring parts',
  };

  return (
    <div className={'px-3 pt-8 pb-16'}>
      <div className='text-3xl text-center text-purple-600 mb-4'>
        Here&apos;s how it works
      </div>
      <div className={Styles.explainer} ref={explainer} data-step={step}>
        <SceneWYSIWYG className={classNames(Styles.stage, step === AnimationStep.WYSIWYG || 'hidden')} height={undefined} width={undefined} ref={stageWYSIWYG}/>
        <SceneFormSubmit className={classNames(Styles.stage, step === AnimationStep.FormSubmit || 'hidden')} height={undefined} width={undefined} ref={stageFormSubmit}/>
        <SceneMultiform className={classNames(Styles.stage, step === AnimationStep.Multiform || 'hidden')} height={undefined} width={undefined} ref={stageMultiform}/>
        <SceneMechanism className={classNames(Styles.stage, step === AnimationStep.Mechanism || 'hidden')} height={undefined} width={undefined} ref={stageMechanism}/>
        <Palette className={sceneItem(flags.palette, Styles.actor)} height={undefined} width={undefined} ref={palette}/>
        <Pointer className={sceneItem(flags.pointer, Styles.actor, Styles.pointer)} height={undefined} width={undefined} ref={pointer}/>
        <Table className={sceneItem(flags.table, Styles.actor)} height={undefined} width={undefined} ref={table}/>
        <div className={sceneItem(flags.createFormBox, Styles.actor)} ref={createFormBox}>
          <CreateForm className={sceneItem(flags.createForm)} height={undefined} width={undefined} ref={createForm}/>
        </div>
        <ReviewForm className={sceneItem(flags.reviewForm, Styles.actor)} height={undefined} width={undefined} ref={reviewForm}/>
      </div>
      <div className='text-center text-lg text-purple-600'>
        {messages[step]}
      </div>
      <div className='flex justify-center mt-3'>
        <button
          type='button'
          onClick={replayAnimation}
          disabled={playing}
          className='flex items-center text-purple-600 disabled:text-gray-300'
        >
          <ReplayIcon className='h-12 w-12'/>
        </button>
      </div>
      <div className='flex justify-center mt-2'>
        <ExplainerProgress step={step} prevStep={prevStep} nextStep={nextStep}/>
      </div>
    </div>
  );
}
