import {
  ANIMATION_TIME_SCALE,
  getAnimationProgress,
} from './explainer_utils';

export function UndrawElement(element: SVGGeometryElement) {
  const pathLength = element.getTotalLength();
  const drawLength = 0;
  element.style.strokeDasharray = `${pathLength} ${pathLength}`;
  element.style.strokeDashoffset = `${pathLength - drawLength}`;
}

export function DrawAnimation(opts: {
  element: SVGGeometryElement,
  duration?: number,
  endDelay?: number,
}): anime.AnimeAnimParams {
  const { element, duration, endDelay } = opts;
  const pathLength = element.getTotalLength();
  return {
    targets: element,
    opacity: 1,
    duration: (duration ?? 500) * ANIMATION_TIME_SCALE,
    endDelay: (endDelay ?? 250) * ANIMATION_TIME_SCALE,
    begin: () => {
      element.setAttribute('marker-end', '');
    },
    update: (anim: anime.AnimeInstance) => {
      // Update gets called repeatedly even when progress >= 1.
      // This forces the dashoffset when we don't want it, so we simply don't update if progress >= 1
      const progress = getAnimationProgress(anim);
      if (progress < 1) {
        const drawLength = pathLength * progress;
        element.style.strokeDasharray = `${pathLength} ${pathLength}`;
        element.style.strokeDashoffset = `${pathLength - drawLength}`;
      }
    },
  };
}

export function DrawArrowAnimation(opts: {
  timeline: anime.AnimeTimelineInstance;
  arrow: SVGSVGElement;
  duration?: number;
  endDelay?: number;
}) {
  const { timeline, arrow, duration, endDelay } = opts;
  const base = opts.timeline.duration;
  const LINE_DURATION = (duration ?? 600) * ANIMATION_TIME_SCALE;
  const LINE_END_DELAY = (endDelay ?? 200) * ANIMATION_TIME_SCALE;
  const line = arrow.querySelector('.path-line') as SVGGeometryElement;
  const head = arrow.querySelector('.path-head') as SVGGeometryElement;

  const pathLength = line.getTotalLength();
  line.style.strokeDasharray = `${pathLength} ${pathLength}`;
  line.style.strokeDashoffset = `${pathLength}`;

  timeline.add({
    targets: line,
    duration: LINE_DURATION,
    endDelay: LINE_END_DELAY,
    begin: () => {
      head.setAttribute('opacity', '0');
    },
    update: (anim: anime.AnimeInstance) => {
      // Update gets called repeatedly even when progress >= 1.
      // This forces the dashoffset when we don't want it, so we simply don't update if progress >= 1
      const progress = getAnimationProgress(anim);
      if (progress < 1) {
        const drawLength = pathLength * progress;
        line.style.strokeDasharray = `${pathLength} ${pathLength}`;
        line.style.strokeDashoffset = `${pathLength - drawLength}`;
      } else {
        const drawLength = pathLength;
        line.style.strokeDasharray = `${pathLength} ${pathLength}`;
        line.style.strokeDashoffset = `${pathLength - drawLength}`;
        head.setAttribute('opacity', '1');
      }
    },
  });

  timeline.add({
    targets: arrow,
    opacity: 1,
    duration: LINE_DURATION / 4,
  }, base);
}

export function RevealAnimation(element: Element) {
  return {
    targets: element,
    opacity: 1,
    duration: 400 * ANIMATION_TIME_SCALE,
    endDelay: 500 * ANIMATION_TIME_SCALE,
  };
}

export function HideAnimation(element: Element) {
  return {
    targets: element,
    opacity: 0,
    duration: 150 * ANIMATION_TIME_SCALE,
  };
}

export function WaitAnimation(time?: number) {
  return {
    targets: '',
    delay: time ?? 250,
  };
}
